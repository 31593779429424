// Colors
$white: #ffffff;
$black: #000000;

$lightBack: #f4ede7;

// Sizes
$base-font-size: 13px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1800px;

// Fonts
$mainFonts: 'Ubuntu', sans-serif;