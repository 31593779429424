.footer {
	padding: 25px 0 0;
	color: #fff;
	font-size: 12px;
	background: rgb(76,137,184);
	background: linear-gradient(360deg, rgba(76,137,184,1) 0%, rgba(26,103,163,1) 100%);
	a {
		display: block;
		color: #fff;
		margin-bottom: 8px;
	}
	span {
		display: block;
		margin-bottom: 8px;
	}
	&__item {
		margin-bottom: 25px;
		&:nth-child(2) {
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 991px) {
		img {
			width: 100%;
		}
	}
}
.copy {
	position: relative;
	display: block;
	max-width: 120px;
	border-radius: 14px;
	background: #00C2D4;
	text-align: right;
	padding: 5px 15px;
	&:after {
		position: absolute;
		display: block;
		content: '';
		left: 10px;
		top: 6px;
		height: 17px;
		width: 17px;
		border-radius: 50%;
		background: transparent;
		border: solid 5px #fff;
	}
}

.footer-logo {
	@media screen and (max-width: 767px) {
		order: 1;
		text-align: center;
		img {
			width: auto;
		}
	}
}