@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

body { height: 100%; font-family: $mainFonts; color: #333333; }

h1, h2, h3, h4, h5, h6 { margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { text-decoration: none; }

p { margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative;}

input,
select,
button { outline: none; }

*, *:before, *:after { box-sizing: border-box; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

// .container {
//   margin: 0 auto;
//   max-width: $content-size;
//   width: 100%;
// }

//Модули
@import "modules/modules";

.text-align-center {
	text-align: center;
}

.hide-nav {
	position: absolute;
	display: none;
	width: 40px;
	height: 40px;
	cursor: pointer;
	top: 7px;
	right: 15px;
	@media screen and (max-width: 575px) { display: block;}
}
.hide-nav__line {
	position: absolute;
	display: block;
	width: 80%;
	height: 3px;
	left: 10%;
	border-radius: 5px;
	background-color: #fa6c00;
	transition: .5s;
}
.hide-nav__line--top { top: 8px;}
.hide-nav__line--middle { top: 18px; opacity: 1;}
.hide-nav__line--bottom { top: 28px;}

.hide-nav {
	&.active {
		.hide-nav__line--top {
			top: 20px;
			transform: rotate(45deg);
			transition: .5s;
		}
	}
}

.hide-nav {
	&.active {
		.hide-nav__line--middle {
			opacity: 0;
			transition: .5s;
		}
	}
}

.hide-nav {
	&.active {
		.hide-nav__line--bottom {
			top: 20px;
			transform: rotate(-45deg);
			transition: .5s;
		}
	}
}

.back-gray {
	position: absolute;
	display: block;
	height: 247px;
	width: 100%;
	top: 0;
	z-index: -1;
	background: rgb(232,232,232);
	background: linear-gradient(360deg, rgba(232,232,232,1) 3%, rgba(255,255,255,1) 100%);
	@media screen and (max-width: 1200px) { height: 240px; }
	@media screen and (max-width: 991px) { height: 223px; }
	@media screen and (max-width: 768px) { display: none; }
}

.main-title {
	text-align: center;
	color: #1564a1;
	font-size: 35px;
	font-weight: normal;
	margin-top: 15px;
	margin-bottom: 15px;
	@media screen and (max-width: 900px) {
		font-size: 25px;
		text-align: center;
	}
	@media screen and (max-width: 512px) {
		font-size: 20px;
		text-align: center !important;
		font-weight: bold;
	}
	&--red {
		color: #EB1400;
		text-align: left;
	}
	&--green {
		color: #2b7a04;
		text-align: left;
	}
	&--orange {
		color: #ff6600;
		text-align: left;
	}
	&--small {
		display: block;
		text-align: center;
		font-weight: bold;
		color: #216ea9;
		font-size: 16px;
		margin: 10px auto;
	}
}
.main-info {
	position: relative;
	font-size: 14px;
	&__item {
		background: rgb(76,137,184);
		background: linear-gradient(180deg, rgba(76,137,184,1) 35%, rgba(89,169,222,1) 100%);
		display: block;
		width: 100%;
		text-align: center;
		padding: 7px 0;
		font-size: 12px;
		color: #fff;
		border-radius: 0 0 7px 7px;
		@media screen and (max-width: 991px) {
			border-radius: 7px;
			margin: 5px 0;
		}
	}
	&__item--add {
		position: relative;
		background: rgb(249,152,55);
		background: linear-gradient(360deg, rgba(249,152,55,1) 3%, rgba(244,106,3,1) 100%);
		&:after {
			content: '+';
			position: absolute;
			font-size: 30px;
			line-height: 32px;
			display: block;
			width: 35px;
			height: 35px;
			right: 12px;
			top: 5px;
			border-radius: 50%;
			border: 2px solid #fff;
			background: rgb(249,152,55);
			background: linear-gradient(360deg, rgba(249,152,55,1) 3%, rgba(244,106,3,1) 100%);
			@media screen and (max-width:1200px) {right: 0}
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
.main-content {
	margin-top: 25px;
	.col-xs-12 {
		margin-bottom: 25px;
	}
}
.main-item__header {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	height: 163px;
	justify-content: center;
	@media screen and (max-width: 1200px) {
		margin-bottom: 5px;
	}
	@media screen and (max-width: 991px) { height: auto; }
	img {
		width: 100%;
	}
	span {
		color: #fff;
		font-weight: bold;
		font-size: 25px;
	}
}

.main-item__content {
	padding: 10px 20px ;
	box-shadow: 5px -2px 15px -8px rgba(0,0,0,0.58);
	@media screen and (max-width: 990px) {
		padding: 10px;
	}
}

.main-item__title {
	position: relative;
	text-align: center;
	font-size: 25px;
	font-family: 'Helvetica';
	font-weight: normal;
	@media screen and (max-width: 1200px) {
		font-size: 20px;
	}
	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 1px;
		margin: 20px 0;
	@media screen and (max-width: 1200px) {
		margin: 10px 0;
	}
	}
	span {
		display: inline-block;
		margin-left: 10px;
		text-transform: uppercase;
	}
	&--red {
		color: #e52817;
		&:after {
			background: rgb(230,40,23);
			background: radial-gradient(circle, rgba(230,40,23,1) 0%, rgba(255,255,255,1) 100%);
		}
	}
	&--green {
		color: #47b212;
		&:after {
			background: rgb(69,173,17);
			background: radial-gradient(circle, rgba(69,173,17,1) 0%, rgba(255,255,255,1) 100%);
		}
	}
	&--orange {
		color: #ff7b0f;
		&:after {
			background: rgb(255,123,15);
			background: radial-gradient(circle, rgba(255,123,15,1) 0%, rgba(255,255,255,1) 100%);
		}
	}
}

.main-item__text {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	a:hover {
		text-decoration: underline;
	}
	&--red {
		a {
			color: #e52817;
		}
	}
	&--green {
		a {
			color: #47b212;
		}
	}
	&--orange {
		a {
			color: #ff7b0f;
		}
	}
}

.main-item__link {
	display: inline-block;
	position: relative;
	margin-top: 30px;
	padding: 5px 0;
	text-align: center;
	width: 100%;
	text-decoration: underline;
	color: #fff;
	&:before {
		display: block;
		width: 100%;
		content: '';
		position: absolute;
		height: 35px;
		top: -35px;
		opacity: .7;
	}
	&--red {
		background: rgb(235,20,0);
		background: linear-gradient(62deg, rgba(235,20,0,1) 35%, rgba(242,106,93,1) 100%);
		&:before {
			background: rgb(229,40,23);
			background: linear-gradient(360deg, rgba(229,40,23,1) 0%, rgba(255,255,255,0) 100%);
		}
	}
	&--green {
		background: rgb(43,122,4);
		background: linear-gradient(62deg, rgba(43,122,4,1) 35%, rgba(130,176,107,1) 100%);
		&:before {
			background: rgb(43,122,4);
			background: linear-gradient(360deg, rgba(43,122,4,1) 0%, rgba(255,255,255,0) 100%);
		}
	}
	&--orange {
		background: rgb(255,89,0);
		background: linear-gradient(90deg, rgba(255,89,0,1) 0%, rgba(255,163,114,1) 100%);
		&:before {
			background: rgb(255,89,0);
			background: linear-gradient(360deg, rgba(255,89,0,1) 0%, rgba(255,255,255,0) 100%);
		}
	}
}

.partners {
	position: relative;
	padding: 25px 0;
	margin-top: 35px;
	background-color: #f4f4f4;
	&::before {
		display: block;
		position: absolute;
		content: '';
		height: 2px;
		width: 100%;
		margin-bottom: 0;
		top: -14px;
		background: #b3b3b3;
	}
	&::after {
		display: block;
		position: absolute;
		content: '';
		height: 8px;
		width: 100%;
		margin-bottom: 2px;
		top: -10px;
		background: #b3b3b3;
	}
	&__title {
		display: block;
		margin-bottom: 10px;
		@media screen and (max-width: 767px) {
			display: inline-block;
			margin: 0 auto 15px;
			text-align: center;
		}
	}
	&__img {
		width: 100%;
		height: auto;
		@media screen and (max-width: 575px) {
			width: auto;
		}
	}
	&__link {
		@media screen and (max-width: 767px) {
			margin-bottom: 25px;
		}
		@media screen and (max-width: 767px) {
			text-align: center;
		}
	}
}

.category {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	label {
		margin: 0 10px 0 0;
	}
	&__btn {
		background: rgb(249,152,55);
		background: linear-gradient(180deg, rgba(249,152,55,1) 35%, rgba(244,106,3,1) 100%);
		border: none;
		-webkit-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		-moz-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		padding: 4px 17px;
		color: #fff;
		border-radius: 5px;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
		cursor: pointer;
	}
}

.inner-content__item {
	border: 1px solid #e8e8e8;
	overflow: hidden;
	border-radius: 15px;
	padding: 20px;
	margin-bottom: 30px;
	font-size: 14px;
	transition: all ease .2s;
	&--red {
		a	{
			color: #e52817;
		}
		&:hover {
			background-color: #FFF3F0;
			transition: all ease .2s;
		}
	}
	&--green {
		a	{
			color: #2b7a04;
		}
		&:hover {
			background-color: #EFFFED;
			transition: all ease .2s;
		}
	}
	&--orange {
		a	{
			color: #ff6600;
		}
		&:hover {
			background-color: #FFFDE5;
			transition: all ease .2s;
		}
	}
	img {
		display: block;
		margin: 15px auto;
	}
}

.inner-content__title {
	display: block;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
}

.inner-content__data {
	display: block;
	margin: 10px 0;
}

.inner-content__category {
	display: flex;
	justify-content: space-between;
}
.inner-content__link {
	display: inline-block;
	margin-left: 10px;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.g-form {
	input[type='text'] {
		border: 2px solid #e8e8e8;
		border-radius: 10px;
		color: #216ea9;
		font-size: 14px;
	}
	input[type='file'] {
		border-radius: 10px;
		border: 2px solid #e8e8e8;
		height: 38px;
	}
	input[type='password'] {
		border-radius: 10px;
		border: 2px solid #e8e8e8;
		height: 38px;
	}
	select {
		border: 2px solid #e8e8e8;
		border-radius: 10px;
		font-size: 14px;
		color: #216ea9;
	}
	textarea {
		border: 2px solid #e8e8e8;
		border-radius: 10px;
		font-size: 14px;
		color: #216ea9;
	}
	&--border {
		border: 1px solid #e8e8e8;
		border-radius: 10px;
		padding: 20px;
		margin-bottom: 25px;
	}
}
.g-form__title {
	display: block;
	margin: 15px auto;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	color: #216ea9;
}

.g-form__btn  {
	display: inherit;
	margin: 0 auto ;
	background: rgb(249,152,55);
	background: linear-gradient(180deg, rgba(249,152,55,1) 35%, rgba(244,106,3,1) 100%);
	border: none;
	-webkit-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
	-moz-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
	box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
	padding: 4px 7px;
	color: #fff;
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 12px;
}
.modal-header {
	border: none;
}
.modal-title {
	color: #216ea9;
	font-weight: bold;
	font-size: 14px;
}
.modal-footer {
	text-align: center;
	font-size: 14px;
	color: #216ea9;
	border: none;
}
.autorization {
	position: relative;
}
.about {
	p {
		margin: 5px 0;
	}
	ul {
		margin-bottom: 10px;
		li {
			margin-left: 15px;
			list-style-type: disc;
		}
	}
}

.add {
	&-link {
		display: block;
		margin-top: 25px;
		transition: all ease .3s;
		text-align: center;
		&:hover {
			transform: scale(1.1);
			transition: all ease .3s;
		}
		img {
			width: 80%;
		}
	}
}

.js-slider {
	position: relative;
	&__wrap {
		padding: 5px;
		img {
			max-height: 310px;
			width: 100%;
		}
	}
	&__nav {
		margin: 15px auto;
		max-width: 80%;
		img {
			margin-right: 15px;
		}
	}
}

.js-slider__arrow {
	display: block;
	position: absolute;
	bottom: 25px;
	width: 20px;
	height: 65px;
	background: red;
	z-index: 5;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&--prev {
		left: 0;
		background-image: url('../images/general/prev.gif');
	}
	&--next {
		right: 0;
		background-image: url('../images/general/next.gif');
	}
}

.det {
	p {
		margin-bottom: 15px;
	}
	span {
		display: block;
	}
}

.det-title {
	display: block;
	font-weight: bold;
}