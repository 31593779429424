.header {
	position: relative;
	font-size: 13px;
	padding-bottom: 15px;
	&::before {
		display: block;
		position: absolute;
		content: '';
		height: 2px;
		width: 100%;
		margin-bottom: 2px;
		background: #e6e6e6;
		bottom: 14px;
	}
	&::after {
		display: block;
		position: absolute;
		content: '';
		height: 8px;
		width: 100%;
		margin-bottom: 2px;
		background: #e6e6e6;
		bottom: 4px;
	}
	&-top {		
		background: rgb(65,136,191);
		background: linear-gradient(180deg, rgba(65,136,191,1) 35%, rgba(33,110,169,1) 100%);
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			@media screen and (max-width: 575px) {
				padding-top: 14px;
				padding-bottom: 14px;
			}
		}
  }
  &-user {
	  display: flex;
	  align-items: center;
	  color: #fff;
	  margin-left: 25%;
	  @media screen and (max-width: 991px) {
		  margin-left: 0;
	  }
	  &__registration {
		  text-decoration: underline;
		  color: #fff;
		  margin-right: 25px;
		  &:hover {
			  text-decoration: none;
			  color: #e8e8e8;
		  }
	  }
	  &__join {
		background: rgb(249,152,55);
		background: linear-gradient(180deg, rgba(249,152,55,1) 35%, rgba(244,106,3,1) 100%);
		border: none;
		-webkit-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		-moz-box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		box-shadow: 4px 9px 11px -5px rgba(0,0,0,0.76);
		padding: 4px 7px;
		color: #fff;
		border-radius: 5px;
		text-transform: uppercase;
		font-size: 12px;
		cursor: pointer;
	}
  }
  &-nav {
	  margin-left: auto;
	  transition: all ease .4s;
	  &.open-nav {
		  display: block;
		  position: absolute;
		  left: 0;
		  top: 54px;
		  transition: all ease .4s;
		  z-index: 10;
		  background: rgb(65,136,191);
		  background: linear-gradient(180deg, rgba(65,136,191,1) 35%, rgba(33,110,169,1) 100%);
	  }
	  @media screen and (max-width: 575px) {
		  position: absolute;
		  left: -500px;
		  top: 54px;
		  transition: all ease .4s;
		  width: 100%;
		  .header-nav__item {
				width: 100%;
		  }
	  }
	  &__list {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  @media screen and (max-width: 575px) {
			  flex-direction: column;
			  width: 100%;
			  align-items: flex-start;
		  }
	  }
	  &__link {
		  display: inline-block;
		  position: relative;
		  color: #fff;
		  transition: all ease .3s;
		  padding: 14px;
		  &.active {
			  background: #55a0d4;
			  &:after {
				  display: block;
				  opacity: 1;
				  border-color: #55a0d4 transparent transparent transparent;
				  &:hover {
					border-color: #1066a8 transparent transparent transparent;
				  }
			  }
		  }
		  @media screen and (max-width: 767px) {
				padding: 14px 7px;
		  }
		  @media screen and (max-width: 575px) {
			padding-left: 15px;
			width: 100%;
			&:after {
				opacity: 0;
			}
		  }
		  &:hover {
			  text-decoration: underline;
			  color: #e8e8e8;
			  background-color: #1066a8;
			  &:after {
				  z-index: 3;
				  display: block;
				  border-color: #1066a8 transparent transparent transparent;
			  }
		  }
		  &:after {
			display: none;
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 42px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 5;
			transition: all ease .2s;
			border-style: solid;
			border-width: 11px 12px 0 12px;
			border-color: #1066a8 transparent transparent transparent;
		  }
	  }
  }
  &-bottom {
	  background: #fff;
	  .container {
		  position: relative;
		  display: flex;
		  height: 155px;
		  padding-bottom: 30px;
		  justify-content: space-between;
		  align-items: flex-end;
		  background: url('../images/general/fon_img.jpg') center center;
		  background-size: contain;
		  background-repeat: no-repeat;
		  @media screen and (max-width: 991px) {
			  flex-wrap: wrap;
			  height: auto;
		  }
	  }
  }
  &-logo {
	position: relative;
	bottom: -10px;
	
	@media screen and (max-width: 991px) {
		bottom: 8px;
	}
  }
  &-search {
	  display: flex;
	  align-items: center;
	  outline: none;
	  margin-left: auto;
	  overflow: hidden;
	  border-radius: 8px;
	  border: 1px solid #b3b3b3;
	  @media screen and (max-width: 550px) {
		flex-wrap: wrap;
		width: 100%;
		border: none;
	}
	  input[type='text'] {
		  color: #216ea9;
		  padding: 2px 7px;
		  border-radius: 7px;
		  border: none;
	  }
	  input[type='radio'] {
		margin-right: 5px;
	  }
  }
  &-label__wrap {
	position: relative;
	display: flex;
	align-items: center;
	color: #fff;
	border-radius: 7px 0 0 7px;
	background: rgb(65,136,191);
	background: linear-gradient(180deg, rgba(65,136,191,1) 35%, rgba(33,110,169,1) 100%);
	@media screen and (max-width: 550px) {
		flex-wrap: wrap;
		width: 100%;
		border-radius: 7px;
	}
	@media screen and (max-width: 473px) { padding-bottom: 10px; justify-content: center;}
	.form-group {
		display: flex;
		align-items: center;
		margin-right: 25px;
		padding: 0;
		margin-bottom: 0;
		@media screen and (max-width: 767px) {
			margin-right: 0;
			&:nth-child(4) {
				margin-right: 10px;
			}
		}
		@media screen and (max-width: 550px) {
			&:nth-child(4) {
				margin-left: auto;
			}
		}
		@media screen and (max-width: 473px) {
			&:nth-child(4) {
				width: 100%;
				margin: 0 10px;
			}
		}
	}
	.form-control {
		height: auto;
		}
	}
}
.check-radio__custom {
	position: absolute;
	display: none;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 7.5px 0 7.5px;
	border-color: #fa6c00 transparent transparent transparent;
}
.check-radio {
	position: relative;
	padding: 13px;
	margin-bottom: 0;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
	input[type='radio'] {
		display: none;
	}
}
input[type='radio']:checked + .check-radio__custom {
	display: block;
}
.search-btn {
	position: relative;
	height: 100%;
	padding: 15px;
	color: #fff;
	font-weight: bold;
	border: none;
	background: rgb(249,152,55);
	cursor: pointer;	
	&:before {
		position: absolute;
		display: block;
		content: '';
		width: 0;
		height: 0;
		top: 0;
		right: -25px;
		border-style: solid;
		border-width: 23px 0 23px 25px;
		border-color: transparent transparent transparent rgb(249,152,55);
		z-index: 3;
	}
	@media screen and (max-width: 991px) {
		position: relative;
		display: flex;
		flex-shrink: 0;
		font-size: 0;
		&:before {
			display: none;
		}
	}
	@media screen and (max-width: 767px) {
		position: absolute;
		height: auto;
		background: transparent;
		right: 10px;
		padding: 0;
		width: 25px;
		height: 25px;
	}
	@media screen and (max-width: 473px) {
		bottom: 10px;
	}
	.icon-search {
		display: none;
		@media screen and (max-width: 991px) {
			display: block;
			width: 17px;
			height: 17px;
			fill: #216ea9;
		}
	}
}

.search-link {
	padding: 13px 13px 13px 30px;
	color: #333;
	flex-shrink: 0;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 35%, rgba(204,204,204,1) 100%);
	@media screen and (max-width: 767px) {
		padding: 13px;
	}
	&:hover {
		color: #216ea9;
		text-decoration: underline;
	}
	@media screen and (max-width: 550px) {width: 100%; text-align: center;}
}

.line {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 4px;
	background: #4c89b8;
}
